import { OrderStatus } from "../enums/OrderStatus";
import { IFbmOrderItemResponse } from "./FbmOrderItem";
import { INotesResponse } from "./Notes";
import { IProductResponse } from "./Product";
import { IUserResponse } from "./User";

interface FbmOrderResponse {
  id: number;
  user: IUserResponse;
  status: OrderStatus;
  items: IFbmOrderItemResponse[];
  createdAt: string;
  notes: INotesResponse[] | null;
  customerName: string | null;
  customerCountryCode: string | null;
  customerAddress1: string | null;
  customerAddress2: string | null;
  customerState: string | null;
  customerCity: string | null;
  customerZipCode: string | null;
  customerPhone: string | null;
  customerEmail: string | null;
  deliveryOption: string | null;
  totalShippingFee: number | null;
  detailUrl: string;
  orderCode: string;
  printUrl: string;
}

interface FbmOrderStatusUpdateRequest {
  status: OrderStatus;
}

interface FbmOrderCreateRequest {
  userId: number;
  notes?: string;
  customerName: string;
  customerCountryCode: string;
  customerAddress1: string;
  customerAddress2?: string;
  customerState: string;
  customerCity: string;
  customerZipCode: string;
  customerPhone: string;
  customerEmail: string;
  items: [
    {
      asin: string;
      quantity: number;
      price: number;
      additionalServices?: Array<{ id: number; quantity: number }> | null;
    }
  ];
}

interface FbmOrderRequest {
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IFbmOrderResponse implements FbmOrderResponse {
  id: number;
  user: IUserResponse;
  status: OrderStatus;
  items: IFbmOrderItemResponse[];
  createdAt: string;
  notes: INotesResponse[] | null;
  customerName: string | null;
  customerCountryCode: string | null;
  customerAddress1: string | null;
  customerAddress2: string | null;
  customerState: string | null;
  customerCity: string | null;
  customerZipCode: string | null;
  customerPhone: string | null;
  customerEmail: string | null;
  deliveryOption: string | null;
  totalShippingFee: number | null;
  detailUrl: string;
  orderCode: string;
  printUrl: string;

  constructor(obj: FbmOrderResponse) {
    this.id = obj.id;
    this.user = new IUserResponse(obj.user);
    this.status = obj.status;
    this.items =
      obj.items?.map((item) => ({
        id: item.id,
        product: new IProductResponse(item.product),
        additionalServices: item.additionalServices ?? [],
        inventoryLogs: item.inventoryLogs ?? [],
        quantity: item.quantity ?? 0,
        fnskLabel: item.fnskLabel ?? "",
        shippingLabel: item.shippingLabel ?? "",
        shippingProvider: item.shippingProvider ?? "",
        totalShippingFee: item.totalShippingFee ?? 0,
        trackingCode: item.trackingCode ?? "",
      })) ?? [];
    this.createdAt = obj.createdAt;
    this.notes = obj.notes ?? [];
    this.customerName = obj.customerName ?? "Unknown";
    this.customerCountryCode = obj.customerCountryCode ?? "N/A";
    this.customerAddress1 = obj.customerAddress1 ?? "N/A";
    this.customerAddress2 = obj.customerAddress2 ?? "N/A";
    this.customerState = obj.customerState ?? "N/A";
    this.customerCity = obj.customerCity ?? "N/A";
    this.customerZipCode = obj.customerZipCode ?? "N/A";
    this.customerPhone = obj.customerPhone ?? "N/A";
    this.customerEmail = obj.customerEmail ?? "N/A";
    this.deliveryOption = obj.deliveryOption ?? "N/A";
    this.totalShippingFee = obj.totalShippingFee ?? 0;
    this.detailUrl = `/fbm-order/detail/${obj.id}`;
    this.orderCode = `ONESHIP-${obj?.user?.externalUserId}-FBM-${obj?.id}`;
    this.printUrl = `/fbm/print/${obj.id}`;
  }
}

export class IFbmOrderStatusUpdateRequest
  implements FbmOrderStatusUpdateRequest
{
  status: OrderStatus;

  constructor(obj: FbmOrderStatusUpdateRequest) {
    this.status = obj.status;
  }
}

export class IFbmOrderCreateRequest implements FbmOrderCreateRequest {
  userId: number;
  notes?: string;
  customerName: string;
  customerCountryCode: string;
  customerAddress1: string;
  customerAddress2?: string;
  customerState: string;
  customerCity: string;
  customerZipCode: string;
  customerPhone: string;
  customerEmail: string;
  items: [
    {
      asin: string;
      quantity: number;
      price: number;
      additionalServices?: Array<{ id: number; quantity: number }> | null;
    }
  ];

  constructor(obj: FbmOrderCreateRequest) {
    this.userId = obj.userId;
    this.notes = obj.notes ?? "";
    this.customerName = obj.customerName;
    this.customerCountryCode = obj.customerCountryCode;
    this.customerAddress1 = obj.customerAddress1;
    this.customerAddress2 = obj.customerAddress2 ?? "";
    this.customerState = obj.customerState;
    this.customerCity = obj.customerCity;
    this.customerZipCode = obj.customerZipCode;
    this.customerPhone = obj.customerPhone;
    this.customerEmail = obj.customerEmail;
    this.items = obj.items;
  }
}

export class IFbmOrderRequest implements FbmOrderRequest {
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: FbmOrderRequest) {
    this.statuses = obj.statuses ?? [];
    this.userId = obj.userId;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedFbmOrdersResponse {
  data: FbmOrderResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedFbmOrdersResponse implements PaginatedFbmOrdersResponse {
  data: IFbmOrderResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedFbmOrdersResponse) {
    this.data = obj.data.map((order) => new IFbmOrderResponse(order));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
