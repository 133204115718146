import http from "../http-common";
import { IFbmOrderItemCreateRequest, IFbmOrderItemUpdateRequest, IFbmOrderItemResponse, IFbmOrderItemLabelResponse } from "../types/FbmOrderItem";

const createFbmOrderItem = (id: number, requestBody: IFbmOrderItemCreateRequest) => {
  return http.post<IFbmOrderItemResponse>(`/api/v2/fbm-orders/${id}/items`, requestBody);
};

const getFbmOrderItemLabel = (id: number, itemId: number) => {
  return http.get<IFbmOrderItemLabelResponse>(`/api/v2/fbm-orders/${id}/items/${itemId}/label`);
}

const createFbmOrderItemLabel = (id: number, itemId: number) => {
  return http.post<IFbmOrderItemLabelResponse>(`/api/v2/fbm-orders/${id}/items/${itemId}/label`);
}

const updateFbmOrderItem = (id: number, itemId: number, requestBody: IFbmOrderItemUpdateRequest) => {
  return http.put<IFbmOrderItemResponse>(`/api/v2/fbm-orders/${id}/items/${itemId}`, requestBody);
};

const deleteFbmOrderItem = (id: number, itemId: number) => {
  return http.delete<void>(`/api/v2/fbm-orders/${id}/items/${itemId}`);
};

const FbmOrderItemService = {
  createFbmOrderItem,
  getFbmOrderItemLabel,
  createFbmOrderItemLabel,
  updateFbmOrderItem,
  deleteFbmOrderItem
};

export default FbmOrderItemService;
