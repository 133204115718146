import React, { useEffect, useState } from "react";
import { Button, Row, Modal } from "antd";
import { openNotification } from "../../../utils";
import { ReactComponent as UpsIcon } from "../../../assets/ups.svg";
import { ReactComponent as FedexIcon } from "../../../assets/fedex.svg";
import EPGTrackIcon from "../../../assets/EPGTrack-logo.png";
import APCIcon from "../../../assets/apc-logo.png";
import { NavLink } from "react-router-dom";
import FbmOrderItemService from "../../../services/FbmOrderItemService";

interface OneamzShippingProps {
  order: any;
  orderItem: any;
  receiver?: any;
}

const OneamzShippingLabel: React.FC<OneamzShippingProps> = ({
  order,
  orderItem,
  receiver,
}) => {
  const [shippingLabel, setShippingLabel] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [carrier, setCarrier] = useState("");

  useEffect(() => {
    getTrackingCode();
    // eslint-disable-next-line
  }, []);

  const getTrackingCode = () => {
    FbmOrderItemService.getFbmOrderItemLabel(order?.id, orderItem?.id)
      .then((response: any) => {
        if (response.data.code === 200) {
          setTrackingNumber(response.data.trackingNumber);
          setCarrier(response.data.carrier);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const createOneamzShippingLabel = (openModal = true) => {
    if (!trackingNumber) {
      FbmOrderItemService.createFbmOrderItemLabel(order?.id, orderItem?.id)
        .then((response: any) => {
          if (response.data.code !== 200) {
            setButtonLoading(false);
            openNotification("warning", "Warning", response.data.message);
          } else if (response.data.code === 200) {
            setButtonLoading(false);
            if (response.data.labelData) {
              const labelData = response.data.labelData.startsWith(
                "data:application/pdf;base64,"
              )
                ? response.data.labelData
                : `data:application/pdf;base64,${response.data.labelData}`;
              setShippingLabel(labelData);
            } else {
              FbmOrderItemService.getFbmOrderItemLabel(order?.id, orderItem?.id)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    if (response.data.labelData) {
                      const labelData = response.data.labelData.startsWith(
                        "data:application/pdf;base64,"
                      )
                        ? response.data.labelData
                        : `data:application/pdf;base64,${response.data.labelData}`;
                      setShippingLabel(labelData);
                    }
                  }
                })
                .catch((e: Error) => {
                  console.log(e);
                });
            }
            setTrackingNumber(response.data.trackingNumber);
            setCarrier(response.data.carrier);
            if (openModal) {
              setIsModalOpen(true);
              setButtonLoading(false);
            }
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setButtonLoading(false);
        });
    } else {
      FbmOrderItemService.getFbmOrderItemLabel(order?.id, orderItem?.id)
        .then((response: any) => {
          if (response.data.code !== 200) {
            setButtonLoading(false);
            openNotification("warning", "Warning", response.data.message);
          } else if (response.data.code === 200) {
            setButtonLoading(false);
            if (response.data.labelData) {
              const labelData = response.data.labelData.startsWith(
                "data:application/pdf;base64,"
              )
                ? response.data.labelData
                : `data:application/pdf;base64,${response.data.labelData}`;
              setShippingLabel(labelData);
            }
            setTrackingNumber(response.data.trackingNumber);
            setCarrier(response.data.carrier);
            if (openModal) {
              setIsModalOpen(true);
              setButtonLoading(false);
            }
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setButtonLoading(false);
        });
    }
  };

  return (
    <>
      <Row className="text-center">
        <Row>
          <Button
            loading={buttonLoading}
            size="small"
            className="btn bg-slate-blue font-color-cloud btn-hover-white btn-text border-radius-lg mr-8"
            onClick={() => createOneamzShippingLabel(true)}
          >
            Oneamz Shipping Label
          </Button>
        </Row>
        <Row>
          {trackingNumber !== "" && carrier === "UPS" && (
            <NavLink
              to={`https://www.ups.com/track?loc=tr_TR&trackNums=${trackingNumber}&track.x=Submit&requester=ST/trackdetails`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <UpsIcon style={{ height: "30px", width: "30px" }} />
              <b>{trackingNumber}</b>
            </NavLink>
          )}
        </Row>
        <Row>
          {trackingNumber !== "" && carrier === "FEDEX" && (
            <NavLink
              to={`https://www.fedex.com/wtrk/track/?tracknumbers=${trackingNumber}`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <FedexIcon style={{ width: "50px" }} /> <b>{trackingNumber}</b>
            </NavLink>
          )}
        </Row>
        <Row>
          {trackingNumber !== "" && carrier === "E_POST" && (
            <NavLink
              to={`https://epgtrack.com/${trackingNumber}`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <img
                src={EPGTrackIcon}
                alt="EPGTrackIcon"
                style={{ width: "50px" }}
              />{" "}
              <b>{trackingNumber}</b>
            </NavLink>
          )}
        </Row>
        <Row>
          {trackingNumber !== "" && carrier === "APC" && (
            <NavLink
              to={`
            https://www.apc-pli.com/apc-tracking-v2.html?id=${trackingNumber}`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <img src={APCIcon} alt="APCIcon" style={{ width: "50px" }} />{" "}
              <b>{trackingNumber}</b>
            </NavLink>
          )}
        </Row>
        <Modal
          title="Oneamz Shipping Label"
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          width={700}
        >
          {shippingLabel ? (
            <iframe
              height={680}
              width={650}
              src={shippingLabel}
              title={`Shipping Label for Order ${order?.orderCode}`}
            />
          ) : (
            <p>Loading shipping label...</p>
          )}
        </Modal>
      </Row>
    </>
  );
};

export default OneamzShippingLabel;
